<script setup lang="ts">
import integrations from '@respell/integrations';
import { PostHogEvents } from '@respell/utils/tracking/types';
import AppDropdown from '~/components/app/AppDropdown.vue';
import { trackEvent } from '~/util/posthog';

const spellStore = useSpellsStore();
const userStore = useUserStore();
const modal = useModal();

const { profile } = storeToRefs(userStore);

const activeStep = ref(0);

const state: Record<number, string | string[] | undefined> = reactive({
  0: undefined,
  1: undefined,
  2: undefined,
  3: undefined,
  4: [],
  5: undefined,
});

const customVals: Record<number, string | undefined> = reactive({
  1: undefined,
  2: undefined,
  5: undefined,
});

const STEP_INDICES = {
  goal: 0,
  industry: 1,
  role: 2,
  companySize: 3,
  tools: 4,
  referralSource: 5,
  startTutorial: 6,
};

const steps = computed(() => {
  return [
    {
      label: `Welcome ${profile.value?.fullName?.split(' ')[0]} <br> What brings you to Respell?`,
      description:
        'Help us personalize your experience by telling a bit about yourself.',
      options: [
        {
          label: 'Automate repetitive tasks with AI to save time',
          value: 'automate',
        },
        {
          label:
            'Connect multiple apps and automate workflows across platforms',
          value: 'connect',
        },
        {
          label: 'I’m looking for an easy to use no-code automation tool',
          value: 'no_code',
        },
        { label: 'I am just exploring', value: 'explore' },
      ],
      columnCount: 1,
    },
    {
      label: 'What industry do you work in?',
      options: [
        { label: 'Software / Tech', value: 'tech' },
        { label: 'Agency / Consultant', value: 'agency' },
        { label: 'Financial Services', value: 'finance' },
        { label: 'Recruiting Services', value: 'recruiting' },
        { label: 'Healthcare', value: 'healthcare' },
        { label: 'Legal', value: 'legal' },
        { label: 'Retail / E-Commerce', value: 'retail' },
        { value: 'other' },
      ],
      columnCount: 2,
    },
    {
      label: 'What best describes your role?',
      options: [
        { label: 'Sales', value: 'sales' },
        { label: 'Marketing', value: 'marketing' },
        { label: 'Operations', value: 'operations' },
        { label: 'HR / Recruiting', value: 'hr' },
        { label: 'Product', value: 'product' },
        { label: 'Engineering', value: 'engineering' },
        { label: 'Design', value: 'design' },
        { value: 'other' },
      ],
      columnCount: 2,
    },
    {
      label: 'How big is your company?',
      options: [
        { label: '1-10', value: '1-10' },
        { label: '10-50', value: '10-50' },
        { label: '50-100', value: '50-100' },
        { label: '100+', value: '100+' },
      ],
      columnCount: 4,
    },
    {
      label: 'Which tools do you use?',
      description:
        'We will give you personalized workflow recommendations based on the apps you choose.',
    },
    {
      label: 'Lastly, how did you hear about us?',
      options: [
        { label: 'X', value: 'x' },
        { label: 'LinkedIn', value: 'linkedin' },
        { label: 'Email', value: 'email' },
        { label: 'Friend / Word of mouth', value: 'friend' },
        { label: 'Partner (Bland, Lakera, etc.)', value: 'partner' },
        { label: 'AI tool directory', value: 'directory' },
        {
          label: 'Search engine',
          value: 'search',
        },
        { value: 'other' },
      ],
      columnCount: 2,
    },
    {
      label: `<p>Thanks <span class="text-primary-500">${profile.value?.fullName?.split(' ')[0]}!</span><br/>Let's create your first spell</p>`,
      description: '(Takes 5 minutes)',
    },
  ];
});

const label = computed(() => steps.value[activeStep.value].label);
const description = computed(() => steps.value[activeStep.value].description);
const options = computed(() => steps.value[activeStep.value].options);
const columnCount = computed(() => steps.value[activeStep.value].columnCount);

async function onSubmit() {
  activeStep.value++;

  const results = Object.keys(state).reduce(
    (acc, key) => {
      const stepKey = Object.keys(STEP_INDICES)[Number(key)];
      acc[stepKey] =
        state[Number(key)] === 'other'
          ? customVals[Number(key)]
          : state[Number(key)];
      return acc;
    },
    {} as Record<string, string | string[] | undefined>,
  );

  await userStore.updateProfile({
    ...results,
    milestones: [...(profile.value?.milestones || []), 'questionnaire'],
  });

  if (results) {
    trackEvent(PostHogEvents.UserCompletedOnboarding, results);
  }
}

async function startTutorial() {
  await spellStore.createSpell();
  modal.close();
}
</script>
<template>
  <UModal prevent-close>
    <UCard :ui="{ base: 'w-full', body: { base: 'flex flex-col gap-6' } }">
      <UProgress
        v-if="activeStep < STEP_INDICES.startTutorial"
        :value="activeStep + 1"
        :max="Object.keys(state).length"
        size="sm"
        class="w-1/2"
      />
      <UButton
        v-else
        :padded="false"
        color="gray"
        variant="link"
        icon="i-ph-x"
        class="absolute top-4 right-4"
        @click="modal.close"
      />

      <UFormGroup
        size="lg"
        :ui="{
          label: { wrapper: 'justify-center' },
          description: 'text-center',
        }"
        class="w-full"
      >
        <template #label>
          <p class="heading-sm text-center" v-html="label" />
        </template>
        <template #description>
          <p class="body dimmed pt-2 pb-6">{{ description }}</p>
        </template>
        <!-- TODO: Refactor once Nuxt UI releases v3 radio groups -->

        <AppDropdown
          v-if="activeStep === STEP_INDICES.tools"
          v-model="state[activeStep]"
          searchable
          multiple
          :options="Object.values(integrations)"
          placeholder="Select tools..."
          searchable-placeholder="Search tools..."
        />

        <span
          v-else-if="activeStep === STEP_INDICES.startTutorial"
          class="flex flex-col gap-2"
        >
          <img src="/tutorial/add-step.gif" class="w-full rounded-lg" />
          <p class="body-sm dimmed text-center px-8">
            Respell has many features. Let's take a quick 2-minute tour through
            the spell editor to learn the basics and create your first spell.
          </p>
        </span>

        <div
          v-else
          class="grid gap-4"
          :class="{
            'grid-cols-1': columnCount === 1,
            'grid-cols-2': columnCount === 2,
            'grid-cols-4': columnCount === 4,
          }"
        >
          <div v-for="option in options" :key="option.value">
            <UInput
              v-if="option.value === 'other'"
              v-model="customVals[activeStep]"
              leading-icon="i-ph-magnifying-glass"
              :color="state[activeStep] === option.value ? 'primary' : 'white'"
              size="lg"
              placeholder="Other (specify)"
              @click="state[activeStep] = option.value"
            />
            <UButton
              v-else
              :label="option.label"
              variant="outline"
              :color="state[activeStep] === option.value ? 'primary' : 'gray'"
              block
              size="lg"
              @click="state[activeStep] = option.value"
            />
          </div>
        </div>
      </UFormGroup>
      <div class="flex gap-4">
        <!-- BACK BUTTON -->
        <UButton
          v-if="
            ![STEP_INDICES.goal, STEP_INDICES.startTutorial].includes(
              activeStep,
            )
          "
          label="Back"
          variant="ghost"
          color="gray"
          size="lg"
          @click="activeStep -= 1"
        />
        <!-- NEXT / ACTION BUTTON -->
        <UButton
          v-if="activeStep === STEP_INDICES.referralSource"
          label="Get Started"
          variant="solid"
          color="primary"
          size="lg"
          @click="onSubmit"
        />
        <UButton
          v-else-if="activeStep === STEP_INDICES.startTutorial"
          icon="i-ph-plus"
          label="New spell"
          variant="solid"
          color="primary"
          size="lg"
          @click="startTutorial"
        />
        <UButton
          v-else
          label="Next"
          :disabled="
            !state[activeStep] ||
            (state[activeStep] === 'other' && !customVals[activeStep]?.length)
          "
          variant="solid"
          color="primary"
          size="lg"
          @click="activeStep += 1"
        />
      </div>
    </UCard>
  </UModal>
</template>
