<script setup lang="ts">
const { plan } = defineProps({
  plan: {
    type: String,
    required: true,
  },
});

const modal = useModal();

// TODO: Move to constants
const planToPlanName: Record<string, string> = {
  starter: 'Starter',
  team: 'Team',
  enterprise: 'Enterprise',
};
</script>

<template>
  <UModal>
    <UCard
      class="w-full"
      :ui="{
        ring: '',
        divide: '',
      }"
    >
      <template #header>
        <div class="flex flex-col items-center">
          <UIcon
            name="i-ph-confetti"
            size="xl"
            class="text-4xl text-primary-500 mb-4"
          />
          <p class="main-title">Upgrade Successful!</p>
        </div>
      </template>
      <div class="flex flex-col items-center">
        <p>
          Congratulations on stepping up to the
          {{ planToPlanName[plan] }} plan!
        </p>
      </div>
      <template #footer>
        <div class="flex flex-col items-center mb-4">
          <UButton size="xl" @click="modal.close">Get Started</UButton>
        </div>
      </template>
    </UCard>
  </UModal>
</template>
