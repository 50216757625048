<script setup lang="ts">
import { format } from 'date-fns';
import LineChart from '~/components/chart/LineChart.vue';

const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const { data: insightData, pending } = await useLazyApi(
  `/api/users/${user.value?.id}/insights`,
);

const selectedMetric = ref('graph_run_count');

const metrics = [
  { key: 'graph_run_count', title: 'Spell Runs' },
  { key: 'step_run_count', title: 'Step Runs' },
];

const chartData = computed(() => {
  if (!insightData.value?.records) return { labels: [], data: [] };
  return {
    labels: insightData.value.records.map((item) => item.date),
    data: insightData.value.records.map((item) => item[selectedMetric.value]),
  };
});

const totalRuns = computed(() => {
  if (!insightData.value) return 0;
  return selectedMetric.value === 'graph_run_count'
    ? insightData.value.totalGraphRuns
    : insightData.value.totalStepRuns;
});

const percentChange = computed(() => {
  if (!insightData.value) return null;
  return selectedMetric.value === 'graph_run_count'
    ? insightData.value.graphRunPercentChange
    : insightData.value.stepRunPercentChange;
});

const percentChangeDisplay = computed(() => {
  const change = percentChange.value;
  if (change === null) return null;
  const sign = change >= 0 ? '↑' : '↓';
  return `${Math.abs(change).toFixed(2)}% ${sign}`;
});

const dateRange = computed(() => {
  if (!insightData.value?.records || insightData.value.records.length === 0)
    return 'No data available';
  const records = insightData.value.records;
  const startDate = new Date(records[0].date);
  const endDate = new Date(records[records.length - 1].date);
  return `${format(startDate, 'MMM d, yyy')} - ${format(endDate, 'MMM d, yyy')}`;
});

const chartColors = {
  graph_run_count: { border: '#9296fa', point: '#f2f3fe' },
  step_run_count: { border: '#5bc27a', point: '#dcf6e1' },
};
</script>

<template>
  <div
    class="border border-gray-200 shrink bg-white rounded-xl h-full w-full flex flex-col p-4"
  >
    <div class="flex justify-between mb-4 w-full items-start">
      <div v-if="insightData" class="flex flex-col items-start">
        <span
          v-if="percentChangeDisplay"
          class="caption flex gap-1 leading-4"
          :class="{
            'text-green-500': percentChange > 0,
            'text-red-500': percentChange < 0,
            'text-gray-500': percentChange === null,
          }"
        >
          <p class="text-gray-500">Tasks performed</p>
          {{ percentChangeDisplay }}
        </span>
        <span class="flex items-baseline gap-2">
          <p class="title">
            {{ totalRuns }}
          </p>
          <p class="caption">runs</p>
        </span>
        <p class="caption text-gray-400 truncate leading-4">
          {{ dateRange }}
        </p>
      </div>
      <USkeleton v-else-if="pending" class="h-20 w-40" />

      <USelectMenu
        v-model="selectedMetric"
        :options="metrics"
        option-attribute="title"
        value-attribute="key"
      />
    </div>

    <LineChart
      v-if="insightData"
      :labels="chartData.labels"
      :data="chartData.data"
      :label="selectedMetric === 'graph_run_count' ? 'Spell Runs' : 'Step Runs'"
      :border-color="chartColors[selectedMetric].border"
      :point-background-color="chartColors[selectedMetric].point"
      :title="
        selectedMetric === 'graph_run_count'
          ? 'Spell Runs Over Time'
          : 'Step Runs Over Time'
      "
      class="h-full w-full"
    />
    <USkeleton v-else-if="pending" class="h-40 w-full" />
  </div>
</template>
